import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as common from '@rushplay/common'
import styled from '@emotion/styled'

import * as icons from './icons'
import { baseStyles } from './menu-item'

const DisabledLoyaltyLink = styled.span`
  ${baseStyles};
  color: ${t.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active {
    background-color: initial;
    box-shadow: initial;
  }
`

const LoyaltyLink = styled(ReactRouter.Link)`
  ${baseStyles};

  &:active {
    box-shadow: none;
    background-image: linear-gradient(48deg, #ffd87a -3%, #b532ab 114%);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 4px 100%;
  }
`

export function LoyaltyMenuItem(props) {
  if (props.disabled) {
    return (
      <DisabledLoyaltyLink data-testid={props.testId}>
        <icons.Loyalty />
        <common.Box
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
        >
          {props.children}
        </common.Box>
      </DisabledLoyaltyLink>
    )
  }

  return (
    <LoyaltyLink
      data-testid={props.testId}
      to={props.to}
      onClick={props.onClick}
    >
      <icons.LoyaltyColored />
      <common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
      >
        {props.children}
      </common.Box>
    </LoyaltyLink>
  )
}

LoyaltyMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
